















import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  props: {
    stepTitle: {
      type: String,
      default: '',
    },
  },
  setup() {},
});
